import Chart from 'chart.js/auto';

class chartUtil {

    static createStockChart(ctx, data) {
        const config = {
            type: 'doughnut',
            data: data,
            options: {
                responsive: true,
                plugins: {
                    legend: {
                        position: 'top',
                    },
                    title: {
                        display: true,
                        text: 'Inventory Stock'
                    }
                }
            }
        };
        new Chart(ctx, config);
    }

    static createEarningsChart(ctx, data) {
        const config = {
            type: 'doughnut',
            data: data,
            options: {
                responsive: true,
                plugins: {
                    legend: {
                        position: 'top',
                    },
                    title: {
                        display: true,
                        text: 'Earnings'
                    }
                }
            }
        };
        new Chart(ctx, config);
    }

    static createVendorsChart(ctx, data) {
        const config = {
            type: 'doughnut',
            data: data,
            options: {
                responsive: true,
                plugins: {
                    legend: {
                        position: 'top',
                    },
                    title: {
                        display: true,
                        text: 'Vendors'
                    }
                }
            }
        };
        new Chart(ctx, config);
    }
}

export default chartUtil;