<template>
  <main>
    <div class="page-not-found-div">
      <label class="main-label page-not-found-label">
        404 Page Not Found!?!
      </label>
    </div>
  </main>
</template>

<script>
export default {
  name: 'PageNotFoundView'
}
</script>

<style scoped>

.page-not-found-div {
  position: absolute;
  text-align: center;
  top: 50%;
  bottom: 50%;
  width: 100%;
}

.page-not-found-label {
  font-size: 100px;
}

</style>