class ApiCaller {

    static async fetchData(uri) {
        const headers = this.getHeaders();

        return await fetch(uri, {
            method: 'GET',
            credentials: 'include',
            headers,
        })
            .then((response) => response.text())
            .then((data) => {
                return data;
            })
            .catch(error => console.log(error));
    }

    static async fetchFile(uri) {
        let headers = new Headers();
        let accessToken = this.getCookie("wowinventory-access-token");

        if(accessToken != null) { headers.append('Authorization', `Bearer ${accessToken}`); }
        headers.append('Access-Control-Allow-Origin', '*');
        headers.append('Accept', 'application/octet-stream');

        return await fetch(uri, {
            method: 'GET',
            credentials: 'include',
            headers,
        })
            .then((response) => response.text())
            .then((data) => {
                return data;
            })
            .catch(error => console.log(error));
    }

    static async sendData(uri, data, method) {
        const headers = this.getHeaders();

        const requestOptions = {
            method: method,
            credentials: 'include',
            headers,
            body: data,
            cache: 'default'
        };

        return await fetch(uri, requestOptions)
            .then(response => response.text())
            .then(text => {
                console.log(text);
                return text;
            })
            .catch(error => {
                console.log(error);
            });
    }

    static async sendUnknownTypeData(uri, data, method) {
        let headers = new Headers();
        headers.append('Access-Control-Allow-Origin', '*');
        let accessToken = this.getCookie("wowinventory-access-token");
        if(accessToken != null) { headers.append('Authorization', `Bearer ${accessToken}`); }

        const requestOptions = {
            method: method,
            credentials: 'include',
            headers,
            body: data,
            cache: 'default'
        };

        return await fetch(uri, requestOptions)
            .then(response => response.text())
            .then(text => {
                console.log(text);
                return text;
            })
            .catch(error => {
                console.log(error);
            });
    }

    static async deleteData(uri) {
        const headers = this.getHeaders();

        return await fetch(uri, {
            method: 'DELETE',
            credentials: 'include',
            headers,
            cache: 'default'
        })
            .then(response => response.text())
            .then((text) => {
                console.log(text);
                return text;
            })
            .catch(error => {
                console.log(error);
            });
    }

    static getHeaders() {
        let headers = new Headers();
        let accessToken = this.getCookie("wowinventory-access-token");

        if(accessToken != null) { headers.append('Authorization', `Bearer ${accessToken}`); }

        headers.append('Access-Control-Allow-Origin', '*');
        headers.append('Content-Type', 'application/json');

        return headers;
    }

    static getCookie(cname) {
        let name = cname + "=";
        let ca = document.cookie.split(';');
        for(let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return null;
    }
}

export default ApiCaller;
