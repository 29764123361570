<template>
  <main class="popup-background">
    <section class="dymo-printer-popup">
      <div class="printer-selection-div">
        <label class="main-label">Select a Dymo Printer</label>
        <select class="main-select-box content-box-spacing" v-model="this.printer">
          <option v-for="printer in printers" :key="printer" :value="printer">{{ printer }}</option>
        </select>
      </div>
      <div class="footer-div">
        <button class="green-button" @click.prevent="$emit('close')">Close</button>
        <button class="green-button" @click.prevent="this.printQR()" v-if="this.printers.length > 0">Print QR</button>
      </div>
    </section>
  </main>
</template>

<script>
import Dymo from 'dymo-connect';

export default {
  name: 'DymoPrinterPopup',
  props: {
    uniqueId: String
  },
  emits: [ 'close' ],
  data() {
    return {
      printers: [],
      printer: null
    }
  },
  created() {
    Dymo.getPrinters()
        .then((response) => response.data)
        .then((data) =>  {
          data.forEach(element => this.printers.push(element.name)); })
        .catch(error => {
          console.log(error);
        });
  },
  methods: {
    async printQR() {
      if(this.printer == null) {
        alert('There are no printers selected!')
        return;
      }

      let xml = '<?xml version="1.0" encoding="utf-8"?>\n' +
          '<DesktopLabel Version="1">\n' +
          '  <DYMOLabel Version="3">\n' +
          '    <Description>DYMO Label</Description>\n' +
          '    <Orientation>Portrait</Orientation>\n' +
          '    <LabelName>Small30332</LabelName>\n' +
          '    <InitialLength>0</InitialLength>\n' +
          '    <BorderStyle>SolidLine</BorderStyle>\n' +
          '    <DYMORect>\n' +
          '      <DYMOPoint>\n' +
          '        <X>0.03999997</X>\n' +
          '        <Y>0.1</Y>\n' +
          '      </DYMOPoint>\n' +
          '      <Size>\n' +
          '        <Width>0.9033334</Width>\n' +
          '        <Height>0.84</Height>\n' +
          '      </Size>\n' +
          '    </DYMORect>\n' +
          '    <BorderColor>\n' +
          '      <SolidColorBrush>\n' +
          '        <Color A="1" R="0" G="0" B="0"></Color>\n' +
          '      </SolidColorBrush>\n' +
          '    </BorderColor>\n' +
          '    <BorderThickness>1</BorderThickness>\n' +
          '    <Show_Border>False</Show_Border>\n' +
          '    <DynamicLayoutManager>\n' +
          '      <RotationBehavior>ClearObjects</RotationBehavior>\n' +
          '      <LabelObjects>\n' +
          '        <QRCodeObject>\n' +
          '          <Name>BARCODE</Name>\n' +
          '          <Brushes>\n' +
          '            <BackgroundBrush>\n' +
          '              <SolidColorBrush>\n' +
          '                <Color A="1" R="1" G="1" B="1"></Color>\n' +
          '              </SolidColorBrush>\n' +
          '            </BackgroundBrush>\n' +
          '            <BorderBrush>\n' +
          '              <SolidColorBrush>\n' +
          '                <Color A="1" R="0" G="0" B="0"></Color>\n' +
          '              </SolidColorBrush>\n' +
          '            </BorderBrush>\n' +
          '            <StrokeBrush>\n' +
          '              <SolidColorBrush>\n' +
          '                <Color A="1" R="0" G="0" B="0"></Color>\n' +
          '              </SolidColorBrush>\n' +
          '            </StrokeBrush>\n' +
          '            <FillBrush>\n' +
          '              <SolidColorBrush>\n' +
          '                <Color A="1" R="0" G="0" B="0"></Color>\n' +
          '              </SolidColorBrush>\n' +
          '            </FillBrush>\n' +
          '          </Brushes>\n' +
          '          <Rotation>Rotation0</Rotation>\n' +
          '          <OutlineThickness>1</OutlineThickness>\n' +
          '          <IsOutlined>False</IsOutlined>\n' +
          '          <BorderStyle>SolidLine</BorderStyle>\n' +
          '          <Margin>\n' +
          '            <DYMOThickness Left="0" Top="0" Right="0" Bottom="0" />\n' +
          '          </Margin>\n' +
          '          <BarcodeFormat>QRCode</BarcodeFormat>\n' +
          '          <Data>\n' +
          '            <DataString>' + this.uniqueId + '</DataString>\n' +
          '          </Data>\n' +
          '          <HorizontalAlignment>Center</HorizontalAlignment>\n' +
          '          <VerticalAlignment>Middle</VerticalAlignment>\n' +
          '          <Size>Large</Size>\n' +
          '          <EQRCodeType>QRCodeText</EQRCodeType>\n' +
          '          <TextDataHolder>\n' +
          '            <Value>' + this.uniqueId + '</Value>\n' +
          '          </TextDataHolder>\n' +
          '          <ObjectLayout>\n' +
          '            <DYMOPoint>\n' +
          '              <X>0.03999997</X>\n' +
          '              <Y>0.1192806</Y>\n' +
          '            </DYMOPoint>\n' +
          '            <Size>\n' +
          '              <Width>0.9033334</Width>\n' +
          '              <Height>0.8207195</Height>\n' +
          '            </Size>\n' +
          '          </ObjectLayout>\n' +
          '        </QRCodeObject>\n' +
          '      </LabelObjects>\n' +
          '    </DynamicLayoutManager>\n' +
          '  </DYMOLabel>\n' +
          '  <LabelApplication>Blank</LabelApplication>\n' +
          '  <DataTable>\n' +
          '    <Columns></Columns>\n' +
          '    <Rows></Rows>\n' +
          '  </DataTable>\n' +
          '</DesktopLabel>';

      let response = await Dymo.printLabel(this.printer, xml)
      console.log(response);
    }
  }
}
</script>

<style scoped>

.dymo-printer-popup {
  display: grid;
  justify-content: center;
  background: lightgray;
  min-width: 450px;
  max-width: 450px;
  min-height: 180px;
  max-height: 180px;
  border-radius: 5px;
  overflow-y: inherit;
  overflow-x: inherit;
  grid-template-rows: 8fr 1fr;
}

.printer-selection-div {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  gap: 5px;
}

.footer-div {
  display: flex;
  margin: 5px 5px 15px;
  justify-content: center;
  gap: 5px;
  height: 50px;
}

</style>