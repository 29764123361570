import { createWebHistory, createRouter } from 'vue-router';

// Components
import ApiCaller from '@/utilities/apiCaller';

import ItemCreateView from '@/views/creation/ItemCreateView';
import ReceiptCreateView from '@/views/creation/ReceiptCreateView';
import TemplateCreateView from '@/views/creation/TemplateCreateView';

import ItemDetailsView from '@/views/details/ItemDetailsView';

import TemplateDetailsView from '@/views/details/TemplateDetailsView';

import InventoryView from '@/views/tables/InventoryView';
import ReceiptsView from '@/views/tables/ReceiptsView';
import TemplatesView from '@/views/tables/TemplatesView';

import DashboardView from '@/views/DashboardView';
import LoginView from '@/views/LoginView';
import PageNotFoundView from '@/views/PageNotFoundView';
import ReceiptDetailsView from '@/views/details/ReceiptDetailsView';
import ReturnCreateView from "@/views/creation/ReturnCreateView.vue";

const routes = [
    // Creation
    { path: '/inventory/create', name: 'CreateItem', component: ItemCreateView },
    { path: '/receipts/create', name: 'ReceiptCreate', component: ReceiptCreateView },
    { path: '/returns/create/:uniqueId', name: 'ReturnCreate', component: ReturnCreateView },
    { path: '/templates/create', name: 'TemplateCreate', component: TemplateCreateView },

    // Dashboard
    { path: '/dashboard', name: 'Dashboard', component: DashboardView },

    // Details
    { path: '/inventory/details/:uniqueId', name: 'ItemDetails', component: ItemDetailsView },
    { path: '/receipts/details/:uniqueId', name: 'ReceiptDetails', component: ReceiptDetailsView },
    { path: '/templates/details/:uniqueId', name: 'TemplatesDetails', component: TemplateDetailsView },

    // Tables
    { path: '/inventory', name: 'Inventory', component: InventoryView },
    { path: '/receipts', name: 'Receipts', component: ReceiptsView },
    { path: '/templates', name: 'Templates', component: TemplatesView },

    // Login
    { path: '/', name: 'Login', component: LoginView },

    // Page Not Found
    { path: '/:catchAll(.*)*', name: 'PageNotFound', component: PageNotFoundView },
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

// Router Guard
router.beforeEach(async (to, from, next) => {
    let isAuthenticated = await ApiCaller.fetchData('/security/isAuthenticated');

    if(to.name === 'Login') {
        if(isAuthenticated === 'true') {
            next({name: 'Inventory'});
        } else {
            next();
        }
        return;
    }

    if(isAuthenticated === 'true') {
         next();
    } else {
        next({ name: 'Login'});
    }
});

export default router ;