<template>
  <TableController :columns="this.columns" :items="this.receipts" :pages="this.pages" :page="this.currentPage" :table="'ReceiptsTable'"
                   @header-selected="this.headerSelected" @item-selected="this.itemSelected"
                   @search="this.search" @show-limit-updated="this.showLimit" @paginate="this.pagination"
                   v-if="this.loaded">
    <template v-slot:TableControlsSlot>
      <button class="green-button" @click.prevent="this.navigateToCreate()">Add New Receipt</button>
    </template>
  </TableController>
</template>

<script>
import ApiCaller from '@/utilities/apiCaller';
import TableController from '@/controllers/TableController';
import router from '@/router';

export default {
  name: 'ReceiptsView',
  components: { TableController },
  data() {
    return {
      columns: [
          'Vendor',
          'Date Purchased',
          'Date Created',
          'Total Cost',
          'Account Transaction'
      ],
      rawReceipts: [],
      totalReceipts: 0,
      rawPages: 0,
      searchInput: '',
      column: 'datepurchased',
      limit: 10,
      page: 1,
      loaded: false
    }
  },
  computed: {
    receipts() {
      return this.rawReceipts || [];
    },
    pages() {
      return this.rawPages || 0;
    },
    currentPage() {
      return this.page || 1;
    }
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      await this.updateReceipts();
      this.loaded = true;
    },
    search(searchInput) {
      this.searchInput = searchInput;
      this.page = 1;
      this.updateReceipts();
    },
    showLimit(limit) {
      this.limit = limit;
      this.page = 1;
      this.updateReceipts();
    },
    pagination(page) {
      this.page = page;
      this.updateReceipts();
    },
    async updateReceipts() {
      let receiptJsonData = await ApiCaller.fetchData(`/api/v1/receipts/filtered/?searchInput=${ this.searchInput }&column=${ this.column }&limit=${ this.limit }&page=${ this.page }`);
      this.rawReceipts = JSON.parse(receiptJsonData);
      this.totalReceipts = await ApiCaller.fetchData(`/api/v1/receipts/filteredCount/?searchInput=${ this.searchInput }&column=${ this.column }&limit=${ this.limit }&page=${ this.page }`)
      this.rawPages = Math.ceil(this.totalReceipts / this.limit);
    },
    headerSelected(header) {
      this.column = header.replaceAll(' ', '').toLowerCase();
      this.updateReceipts();
    },
    itemSelected(uniqueId) {
      router.push({ path: 'receipts/details/' + uniqueId });
    },
    navigateToCreate() {
      router.push({ path: 'receipts/create'});
    }
  }
}
</script>