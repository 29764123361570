<template>
  <TableController :columns="this.columns" :items="this.templates" :pages="this.pages" :page="this.currentPage" :table="'TemplatesTable'"
                   @header-selected="this.headerSelected" @item-selected="this.itemSelected"
                   @search="this.search" @show-limit-updated="this.showLimit" @paginate="this.pagination"
                   v-if="this.loaded">
    <template v-slot:TableControlsSlot>
      <button class="green-button" @click.prevent="this.navigateToCreate()">Add New Template</button>
    </template>
  </TableController>
</template>

<script>
import ApiCaller from '@/utilities/apiCaller';
import TableController from '@/controllers/TableController';
import router from '@/router';

export default {
  name: 'TemplatesView',
  components: { TableController },
  data() {
    return {
      columns: [
          'Item Name',
          'Item Type',
          'Sub Item Type',
          'Unit Cost',
          'Markup'
      ],
      rawTemplates: [],
      totalTemplates: 0,
      rawPages: 0,
      searchInput: '',
      column: 'itemname',
      limit: 10,
      page: 1,
      loaded: false
    }
  },
  computed: {
    templates() {
      return this.rawTemplates || [];
    },
    pages() {
      return this.rawPages || 0;
    },
    currentPage() {
      return this.page || 1;
    }
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      await this.updateTemplates();
      this.loaded = true;
    },
    search(searchInput) {
      this.searchInput = searchInput;
      this.page = 1;
      this.updateTemplates();
    },
    showLimit(limit) {
      this.limit = limit;
      this.page = 1;
      this.updateTemplates();
    },
    pagination(page) {
      this.page = page;
      this.updateTemplates();
    },
    async updateTemplates() {
      let templatesJsonData = await ApiCaller.fetchData(`/api/v1/templates/filtered/?searchInput=${ this.searchInput }&column=${ this.column }&limit=${ this.limit }&page=${ this.page }`);
      this.rawTemplates = JSON.parse(templatesJsonData);
      this.totalTemplates = await ApiCaller.fetchData(`/api/v1/templates/filteredCount/?searchInput=${ this.searchInput }&column=${ this.column }&limit=${ this.limit }&page=${ this.page }`);
      this.rawPages = Math.ceil(this.totalTemplates / this.limit);
    },
    headerSelected(header) {
      this.column = header.replaceAll(' ', '').toLowerCase();
      this.updateTemplates();
    },
    itemSelected(uniqueId) {
      router.push({ path: 'templates/details/' + uniqueId });
    },
    navigateToCreate() {
      router.push({ path: 'templates/create'});
    }
  }
}
</script>