<template>
  <main class="popup-background">
    <section class="autotask-popup">
      <div class="content-div">
        <div class="autotask-ticket" v-for="ticket in tickets" :key="ticket" @click.prevent="this.getTicket(ticket)">
          <span class="autotask-ticket-info">{{ ticket.company.name }}</span>
          <span class="autotask-ticket-info">{{ ticket.title }}</span>
          <span id="ticketNumber" class="autotask-ticket-info">{{ ticket.ticketNumber }}</span>
          <span class="autotask-ticket-info">{{ ticket.status }}</span>
        </div>
      </div>
      <div class="footer-div">
        <button class="green-button" @click.prevent="$emit('close')">Close</Button>
      </div>
    </section>
  </main>
</template>

<script>
import ApiCaller from '@/utilities/apiCaller';

export default {
  name: 'AutoTaskPopup',
  emits: ['close', 'transferData'],
  data() {
    return {
      tickets: []
    }
  },
  created() {
    this.getTickets();
  },
  methods: {
    async getTickets() {
      let jsonData = await ApiCaller.fetchData('/autotask/getTickets');
      this.tickets = JSON.parse(jsonData);
    },
    async getTicket(ticket) {
      this.$emit('transferData', ticket);
    }
  }
}
</script>

<style scoped>

.autotask-popup {
  display: grid;
  justify-content: center;
  background: lightgray;
  min-width: 800px;
  min-height: 600px;
  border-radius: 5px;
  overflow-y: inherit;
  overflow-x: inherit;
  grid-template-rows: 8fr 1fr;
}

.content-div {
  height: 500px;
  width: 750px;
  max-height: 500px;
  max-width: 750px;
  min-height: 500px;
  min-width: 750px;
  margin-top: 20px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.footer-div {
  display: flex;
  margin-top: 5px;
  margin-bottom: 5px;
  justify-content: center;
}

/* Auto Task Item */

.autotask-ticket {
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 2fr;
  height: 70px;
  min-height: 70px;
  max-height: 70px;
  align-items: center;
  border-radius: 5px;
  margin: 10px 5px 5px 5px;
  box-shadow: 0 5px 7px -1px rgba(51, 51, 51, 0.23);
  cursor: pointer;
  transition: transform 0.25s cubic-bezier(0.7, 0.98, 0.86, 0.98), box-shadow 0.25s cubic-bezier(0.7, 0.98, 0.86, 0.98);
  background-color: #fff;
}
.autotask-ticket:hover {
  transform: scale(0.95);
  background-color: lightblue;
}

.autotask-ticket-info {
  color: #979cb0;
  font-weight: 600;
  font-size: 15px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  letter-spacing: 0.02rem;
  margin-left: 12px;
}

</style>