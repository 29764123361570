<template>
  <main class="main-page">
    <ConfirmationPopup v-if="this.confirmationPopupEnabled" @confirm="this.remove()" @cancel="this.confirmationPopupEnabled=false" :message="'Are you sure you want to remove this item?'"/>
    <DymoPrinterPopup v-if="this.dymoPrinterPopupEnabled" @close="this.dymoPrinterPopupEnabled=false" :unique-id="this.uniqueId"/>
    <label class="main-title">Item Details</label>
    <ItemDetails ref="itemDetails" :item-data="this.item" :shipping="this.shipping"/>
    <div class="button-section">
      <button class="green-button details-buttons" @click.prevent="this.update()">Update</button>
      <button class="green-button details-buttons" @click.prevent="this.confirmationPopupEnabled=true">Remove</button>
      <button class="green-button details-buttons" @click.prevent="this.navigateToReceipt()" v-if="this.item.receiptId != null">Receipt</button>
      <button class="green-button details-buttons" @click.prevent="this.dymoPrinterPopupEnabled=true">Print QR</button>
    </div>
  </main>
</template>

<script>
import {useRoute} from 'vue-router';
import ApiCaller from '@/utilities/apiCaller';
import Calculations from '@/utilities/calculations';
import ItemChecker from '@/utilities/itemChecker';
import ItemRemapper from '@/utilities/itemRemapper';
import TicketChargeUtil from '@/utilities/ticketChargeUtil';
import ItemDetails from '@/components/details/ItemDetails';
import ConfirmationPopup from '@/components/popups/ConfirmationPopup';
import DymoPrinterPopup from '@/components/popups/DymoPrinterPopup';

import router from '@/router';

export default {
  name: "ItemDetailsView",
  components: { ConfirmationPopup, ItemDetails, DymoPrinterPopup },
  data() {
    return {
      uniqueId: useRoute().params.uniqueId.toString(),
      item: {},
      confirmationPopupEnabled: false,
      dymoPrinterPopupEnabled: false,
      ticket: null,
      shipping: 0
    }
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      const uri = '/api/v1/items/?uniqueId=' + this.uniqueId;
      let jsonData = await ApiCaller.fetchData(uri);

      if(jsonData === '[]') {
        await router.push({path: '/inventory'});
        return;
      }

      this.item = JSON.parse(jsonData)[0];

      // Only grab shipping if there is a receipt.
      if(this.item.receiptId == null) { return; }

      let receiptUri = '/api/v1/receipts/?uniqueId=' + this.item.receiptId;
      let receiptData = await ApiCaller.fetchData(receiptUri);
      let receipt = JSON.parse(receiptData)[0];

      if(typeof receipt === 'undefined') { return; }
      if(typeof receipt.shippingAmount === 'undefined') { return; }
      if(receipt.shippingAmount === 0) { return; }

      let itemsUri = '/api/v1/items/?receiptId=' + this.item.receiptId + '&itemName=' + this.item.itemName;
      let itemsData = await ApiCaller.fetchData(itemsUri);
      let items = JSON.parse(itemsData);

      let quantity = items.length;

      this.shipping = Calculations.calculateShipping(this.item.unitCost, quantity, receipt.totalCost, receipt.shippingAmount);
    },
    async update() {
      this.item = this.$refs.itemDetails.item;
      this.ticket = this.$refs.itemDetails.ticket;

      if(ItemChecker.isValidItem(this.item) === false) { return; }

      //Markup & Shipping Calculations
      this.item.unitPrice = Number((Calculations.calculateUnitPrice(this.item.unitCost, this.item.markup) + this.shipping).toFixed(2));

      if(this.ticket !== null) {
        this.item.chargeId = await TicketChargeUtil.getChargeId(this.item, this.ticket);
        this.item.itemStatus = 'CHARGED';
      }

      const uri = '/api/v1/items/update';

      const stringifyData = JSON.stringify(ItemRemapper.remap(this.item));

      let response = await ApiCaller.sendData(uri, stringifyData, 'PUT');

      if(response.includes('Success')) {
        await router.push({path: '/inventory'});
      }
    },
    async remove() {
      this.confirmationPopupEnabled = false;
      const uri = '/api/v1/items/delete/uniqueId=' + this.uniqueId;
      let response = await ApiCaller.deleteData(uri);

      if(response.includes('Success')) {
        await router.push({path: '/inventory'});
      } else {
        alert('Cannot delete item: ' + this.item.uniqueId + " ERROR: "  + response);
      }
    },
    navigateToReceipt() {
      if(this.item.receiptId == null) { return; }
      window.open('/receipts/details/' + this.item.receiptId);
    }
  }
}
</script>

<style scoped>

.main-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button-section {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  gap: 10px;
}

.details-buttons {
  margin-top: 10px;
  margin-bottom: 10px;
  height: 50px;
  width: 100px;
}
</style>