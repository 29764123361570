<template>
  <tr class="main-tr" v-for="td in tableData" :key="td">
    <td class="main-td" @click.prevent="this.itemSelected(td.uniqueId)" @contextmenu.prevent="itemSelectedNewTab(td.uniqueId)">{{ td.itemName }}</td>
    <td class="main-td" @click.prevent="this.itemSelected(td.uniqueId)" @contextmenu.prevent="itemSelectedNewTab(td.uniqueId)">{{ td.itemType }}</td>
    <td class="main-td" @click.prevent="this.itemSelected(td.uniqueId)" @contextmenu.prevent="itemSelectedNewTab(td.uniqueId)">{{ td.subItemType }}</td>
    <td class="main-td" @click.prevent="this.itemSelected(td.uniqueId)" @contextmenu.prevent="itemSelectedNewTab(td.uniqueId)">${{ td.unitCost }}</td>
    <td class="main-td" @click.prevent="this.itemSelected(td.uniqueId)" @contextmenu.prevent="itemSelectedNewTab(td.uniqueId)">${{ td.unitPrice }}</td>
    <td class="main-td">
      <a target="_blank" rel="noopener noreferrer" :href="'https://ww3.autotask.net/Autotask/AutotaskExtend/ExecuteCommand.aspx?Code=OpenTicketDetail&TicketNumber=' + td.ticketNumber">{{ td.ticketNumber }}</a>
    </td>
    <td class="main-td" @click.prevent="this.itemSelected(td.uniqueId)" @contextmenu.prevent="itemSelectedNewTab(td.uniqueId)">{{ td.itemStatus }}</td>
    <td class="main-td" @click.prevent="this.itemSelected(td.uniqueId)" @contextmenu.prevent="itemSelectedNewTab(td.uniqueId)">{{ td.itemLocation }}</td>
    <td class="main-td" @click.prevent="this.itemSelected(td.uniqueId)" @contextmenu.prevent="itemSelectedNewTab(td.uniqueId)">{{ td.datePurchased }}</td>
    <td class="main-td" @click.prevent="this.itemSelected(td.uniqueId)" @contextmenu.prevent="itemSelectedNewTab(td.uniqueId)">{{ td.dateSold }}</td>
    <td class="main-td" @click.prevent="this.itemSelected(td.uniqueId)" @contextmenu.prevent="itemSelectedNewTab(td.uniqueId)">{{ td.vendor }}</td>
    <td class="main-td">
      <a target="_blank" rel="noopener noreferrer" :href="'https://go.xero.com/AccountsReceivable/View.aspx?InvoiceID=' + td.invoiceId" v-if="td.invoiceNumber !== 0">INV-{{ td.invoiceNumber }}</a>
    </td>
  </tr>
</template>

<script>

export default {
  name: 'InventoryTable',
  props: {
    items: Array
  },
  emits: ['item-selected'],
  computed: {
    tableData() {
      return this.items || []
    }
  },
  methods: {
    itemSelected(uniqueId) {
      this.$emit('item-selected', uniqueId);
    },
    itemSelectedNewTab(uniqueId) {
      window.open('/inventory/details/' + uniqueId);
    }
  }
}
</script>

<style scoped>
</style>