<template>
  <AutoTaskPopup v-if="this.autoTaskPopupEnabled" @close="this.autoTaskPopupEnabled=false" @transferData="this.setTicketNumber"/>
  <div>
    <!-- Item Name -->
    <div class="row-content">
      <label class="main-label">Item Name</label>
      <input class="main-textbox input-box" type="text" maxlength="100" placeholder="Item Name" v-model="this.item.itemName" :disabled="this.isDisabled()"/>
    </div>

    <!-- Item Type -->
    <div class="row-content">
      <label class="main-label">Item Type</label>
      <select class="main-select-box input-box" v-model="this.item.itemType" :disabled="this.isDisabled()">
        <option v-for="itemType in itemTypes" :key="itemType" :value="itemType">{{ itemType }}</option>
      </select>
    </div>

    <!-- Sub Item Type -->
    <div class="row-content">
      <label class="main-label">Sub-Item Type</label>
      <select class="main-select-box input-box" v-model="this.item.subItemType" :disabled="this.isDisabled()">
        <option v-for="subItemType in subItemTypes" :key="subItemType" :value="subItemType">{{ subItemType }}</option>
      </select>
    </div>

    <!-- Unit Cost -->
    <div class="row-content">
      <label class="main-label">Unit Cost</label>
      <input class="main-textbox input-box" type="number" placeholder="Unit Cost" min="0.00" step="0.25" v-model="this.item.unitCost" :disabled="this.isDisabled()"/>
    </div>

    <!-- Markup -->
    <div class="row-content">
      <label class="main-label">Markup</label>
      <input class="main-textbox input-box" type="number" placeholder="Markup" min="0.00" step="0.25" v-model="this.item.markup" :disabled="this.item.itemStatus === 'COMPLETED'"/>
    </div>

    <!-- Item Total -->
    <div class="total-content">
      <label class="main-label">
        Total: ${{ this.calculateTotal }}
      </label>
    </div>

    <!-- Ticket Information -->
    <div class="ticket-content">
      <button class="green-button ticket-button" @click.prevent="this.openAutoTaskPopup()" :disabled="this.item.itemStatus === 'COMPLETED'">AT</button>
      <button class="green-button ticket-button" @click.prevent="this.removeFromTicket()" :disabled="this.item.itemStatus === 'COMPLETED'" v-if="this.item.ticketNumber !== null && this.item.ticketNumber !== ''">X</button>
      <label class="main-label">{{ this.item.ticketNumber }}</label>
    </div>

    <!-- Item Location -->
    <div class="row-content">
      <label class="main-label">Item Location</label>
      <input class="main-textbox input-box" type="text" maxlength="50" placeholder="Item Location" v-model="this.item.itemLocation" :disabled="this.item.itemStatus === 'COMPLETED'"/>
    </div>

    <!-- Item Description -->
    <div class="row-content">
      <label class="main-label">Description</label>
      <textarea class="main-rich-textbox input-rich-textbox" placeholder="Description" maxlength="200" v-model="this.item.description" :disabled="this.item.itemStatus === 'COMPLETED'"/>
    </div>

    <!-- Item Notes -->
    <div class="row-content">
      <label class="main-label">Notes</label>
      <textarea class="main-rich-textbox input-rich-textbox" placeholder="Notes" maxlength="200" v-model="this.item.notes" :disabled="this.item.itemStatus === 'COMPLETED'"/>
    </div>
  </div>
</template>

<script>
import ApiCaller from '@/utilities/apiCaller';
import AutoTaskPopup from '@/components/popups/AutoTaskPopup';
import { itemTypes } from '@/types/ItemType';
import subItemTypes from '@/types/SubItemType';
import { itemStatuses } from '@/types/ItemStatus';
import calculations from '@/utilities/calculations';

export default {
  name: 'ItemDetails',
  components: { AutoTaskPopup },
  props: {
    itemData: Object,
    shipping: Number
  },
  data() {
    return {
      autoTaskPopupEnabled: false,
      itemStatuses: itemStatuses,
      itemTypes: itemTypes,
      rawItem: {
        itemName: null,
        itemType: null,
        subItemType: null,
        unitCost: '',
        markup: '',
        unitPrice: '',
        ticketNumber: '',
        itemStatus: '',
        itemLocation: '',
        datePurchased: '',
        dateSold: '',
        description: '',
        notes: '',
        vendor: null
      },
      ticket: null
    }
  },
  computed: {
    item() {
      if(this.itemData == null) {
        return this.rawItem;
      } else {
        return this.itemData;
      }
    },
    subItemTypes() {
      if(this.item.itemType === null) {
        return [];
      } else {
        let itemType = this.item.itemType;
        return subItemTypes.getSubItemTypes(itemType);
      }
    },
    calculateTotal() {
      if(this.item.receiptId != null) {
        return Number((calculations.calculateUnitPrice(this.item.unitCost, this.item.markup) + this.shipping).toFixed(2));
      } else {
        return calculations.calculateUnitPrice(this.item.unitCost, this.item.markup);
      }
    }
  },
  methods: {
    openAutoTaskPopup() {
      if(this.item.description == null || this.item.description.length == 0) {
        alert('The item\'s description cannot be empty!')
      } else {
        this.autoTaskPopupEnabled = true;
      }
    },
    isDisabled() {
      if(this.item.itemStatus === 'CHARGED') { return true; }
      if(this.item.itemStatus === 'COMPLETED') { return true; }
      return this.item.receiptId != null;
    },
    setTicketNumber(ticket) {
      this.autoTaskPopupEnabled = false;
      this.item.ticketNumber = ticket.ticketNumber;
      this.ticket = ticket;
    },
    async removeFromTicket() {
      const uri = '/autotask/removeTicketCharge';

      const stringifyData = JSON.stringify(this.item);

      let response = await ApiCaller.sendData(uri, stringifyData, 'PUT');

      if (response.includes('Success')) {
        location.reload();
        console.log(response);
      }
    }
  }
}
</script>

<style scoped>

.row-content {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 10px;
}

.total-content {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 10px;
  align-self: center;
}

.ticket-content {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.ticket-button {
  height: 50px;
  width: 75px;
}

.input-box {
  width: 95%;
}

.input-rich-textbox {
  width: 95%;
}

</style>