<template>
  <main class="main-page">
    <!-- Data Section -->
    <section class="data-section">
      <canvas class="doughnut-canvas" id="stockChart"></canvas>
      <canvas class="doughnut-canvas" id="earningsChart"></canvas>
      <canvas class="doughnut-canvas" id="vendorsChart"></canvas>
    </section>
  </main>
</template>

<script>
import ApiCaller from '@/utilities/apiCaller';
import chartUtil from '@/utilities/chartUtil';

export default {
  name: 'DashboardView',
  data() {
    return {
      instockTotal: 0,
      chargedTotal: 0,
      potentialEarningsTotal: 500,
      actualEarningsTotal: 20
    }
  },
  mounted() {
    this.createStockChart();
    this.createEarningsChart();
    this.createVendorsChart();
  },
  methods: {
    async createStockChart() {
      const instockUri = '/api/v1/items/count?itemStatus=INSTOCK';
      let instockData = await ApiCaller.fetchData(instockUri);

      this.instockTotal = instockData;

      const chargedUri = '/api/v1/items/count?itemStatus=CHARGED';
      let chargedData = await ApiCaller.fetchData(chargedUri);

      this.chargedTotal = chargedData;

      const ctx = document.getElementById("stockChart").getContext("2d");

      const data = {
        labels: [
          'Instock',
          'Charged'
        ],
        datasets: [{
          label: 'Inventory Stock',
          data: [this.instockTotal, this.chargedTotal],
          backgroundColor: [
            'rgb(116, 174, 53)',
            'rgb(164, 224, 99)',
          ]
        }]
      };

      chartUtil.createStockChart(ctx, data);
    },
    async createEarningsChart() {
      const ctx = document.getElementById("earningsChart").getContext("2d");

      const data = {
        labels: [
            'Potential',
            'Actual'
        ],
        datasets: [{
          label: 'Earning',
          data: [this.potentialEarningsTotal, this.actualEarningsTotal],
          backgroundColor: [
              'rgb(247, 91, 91)',
              'rgb(235, 44, 44)',
          ]
        }]
      };

      chartUtil.createEarningsChart(ctx, data);
    },
    async createVendorsChart() {
      const ctx = document.getElementById("vendorsChart").getContext("2d");

      const data = {
        labels: [
            'Amazon',
            'Newegg',
            'Ebay',
            'Other'
        ],
        datasets: [{
          label: 'Vendors',
          data: [5, 10, 4, 2],
          backgroundColor: [
              'rgb(0, 71, 100)',
              'rgb(0, 171, 240)',
              'rgb(85, 206, 255)',
              'rgb(145, 224, 255)'
          ]
        }]
      }

      chartUtil.createVendorsChart(ctx, data);
    }
  }
}
</script>

<style scoped>
.main-page {
  display: flex;
  justify-content: center;
}

.data-section {
  display: flex;
  justify-content: center;
  align-content: center;
  margin-top: 50px;
  border-radius: 0.75rem;
  background: linear-gradient(to right bottom,
  rgba(34, 38, 41, 0.7),
  rgba(34, 38, 41, 0.6));
  width: 80vw;
  height: 80vh;
  gap: 50px;
}

.doughnut-canvas {
  max-height: 500px;
  max-width: 500px;
  min-height: 500px;
  min-width: 500px;
  margin-bottom: 10px;
}
</style>