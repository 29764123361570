<template>
  <main class="main-page">
    <form class="login-section">
      <div class="content">
        <img src="../assets/logo.png" class="logo" alt=""/>
      </div>
      <div class="content">
        <button class="green-button login-button" type="button" v-on:click="this.singleSignOn()">Single Sign on (SSO)</button>
      </div>
      <p class="or-divider">──────── OR ────────</p>
      <div class="content">
        <input class="main-textbox login-input" name="username" type="text" placeholder="Username" v-model="this.username" maxlength="25"/>
      </div>
      <div class="content">
        <input class="main-textbox login-input" name="password" type="password" placeholder="Password" v-model="this.password" maxlength="25"/>
      </div>
      <div class="content">
        <button class="green-button login-button" type="button" v-on:click="this.login()">Sign In</button>
      </div>
      <div> </div>
    </form>
    <p class="copyright">© 2023 - Wowlinx</p>
  </main>
</template>

<script>
import router from '@/router';

export default {
  name: 'LoginComponent',
  data() {
    return {
      username: '',
      password: ''
    }
  },
  methods: {
    singleSignOn() {
      window.location.replace('https://auth.wowlinx.app/auth?url=https://inventory.wowlinx.app');
    },
    async login() {
      const uri = '/authentication?username=' + this.username + '&password=' + this.password;
      fetch(uri, {
        method: 'GET',
        headers: new Headers({
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json'
        })
      })
          .then((response) => {
            response.text(); // Does nothing...
            router.push({ path: '/dashboard'});
          })
          .catch((error) => console.log(error));
    }
  }
}
</script>

<style scoped>

.main-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 325px;
  max-width: 375px;
  height: 600px;
  background: linear-gradient(to right bottom,
  rgba(34, 38, 41, 0.7),
  rgba(34, 38, 41, 0.6));
  border-radius: 0.75rem;
  margin-top: 75px;
  margin-left: 10px;
  margin-right: 10px;
  gap: 25px;
}

.login-input {
  width: 300px;
}

.logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.or-divider {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 15px;
  color: lightgray;
}

.login-button {
  width: 300px;
  height: 50px;
}

.copyright {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 13px;
  color: lightgray;
  margin-top: 2px;
}

</style>