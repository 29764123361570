<template>
  <main class="main-page">
    <div>
      <!-- Search Features -->
      <section class="search-controls-section">
        <div class ="filter-div">
          <span class="main-label">Show </span>
          <select v-model="this.pageLimit" @change="this.showLimitEvent()">
            <option v-for="si in showItems" :key="si" :value="si">{{ si }}</option>
          </select>
        </div>

        <div class="filter-controls">
          <div class="search-div">
            <input type="search" class="main-textbox" placeholder="..." v-model="this.searchInput" @keydown.enter="this.searchEvent(this.searchInput)">
            <button class="green-button search-button" @click.prevent="this.searchEvent(this.searchInput)">Search</button>
          </div>
          <div class="table-control-slot">
            <slot name="TableControlsSlot"/>
          </div>
        </div>
      </section>

      <!-- Table -->
      <section class="table-content-section">
        <BaseTable :columns="this.columns" :items="this.filteredItems" :table="this.table" @header-selected="this.headerSelected" @item-selected="this.itemSelected"/>
      </section>

      <!-- Pagination -->
      <section class="pagination-section">
        <!-- Pagination Information -->
        <div class ="pagination-info-div">
          <p class="main-label">{{ this.currentPage }} of {{ this.pages }} pages...</p>
        </div>

        <!-- Pagination Buttons -->
        <div class="pagination-div">
          <button class="pagination-button" @click.prevent="this.paginateEvent(1)">First</button>
          <button class="pagination-button" :disabled="this.currentPage === 1" @click.prevent="this.paginateEvent(this.currentPage - 1)">Prev</button>

          <!-- Page Buttons 1, 2, 3, etc -->

          <button class="pagination-button" :disabled="this.currentPage === this.pages" @click.prevent="this.paginateEvent(this.currentPage + 1)">Next</button>
          <button class="pagination-button" @click.prevent="this.paginateEvent(this.pages)">Last</button>
        </div>
      </section>
    </div>
  </main>
</template>

<script>
import BaseTable from '@/components/tables/BaseTable';

export default {
  name: 'TableController',
  components: { BaseTable },
  props: {
    table: String,
    columns: Array,
    items: Array,
    pages: Number,
    page: Number
  },
  emits: ['header-selected', 'item-selected', 'search', 'show-limit-updated', 'paginate'],
  data() {
    return {
      showItems: [5, 10, 15, 25, 50, 75, 100],
      pageLimit: 10,
      searchInput: '',
      searchedItems: []
    }
  },
  computed: {
    filteredItems() {
      return this.items || []
    },
    currentPage() {
      return this.page || 1;
    }
  },
  methods: {
    searchEvent() {
      this.$emit('search', this.searchInput);
    },
    showLimitEvent() {
      this.$emit('show-limit-updated', this.pageLimit);
    },
    paginateEvent(page) {
      this.$emit('paginate', page);
    },
    headerSelected(header) {
      this.$emit('header-selected', header);
    },
    itemSelected(uniqueId) {
      this.$emit('item-selected', uniqueId);
    }
  }
}
</script>

<style scoped>

.main-page {
  padding-right: 15px;
  padding-left: 15px;
  margin-left: auto;
  margin-right: auto;
}

.search-controls-section {
  display: grid;
  margin-top: 80px;
  margin-bottom: 10px;
}

.filter-div {
  margin-left: 5px;
  margin-bottom: 10px;
}

.filter-controls {
  display: flex;
}

.search-div {
  display: flex;
  gap: 10px;
}

.search-button {
  height: 50px;
}

.table-control-slot {
  display: flex;
  justify-content: right;
  width: 100%;
  gap: 5%;
}

.table-content-section {
  overflow-x: scroll;
}

.pagination-section {
  display: grid;
  justify-content: center;
  width: auto;
  margin: 10px auto;
}

.pagination-info-div {
  display: grid;
  justify-content: center;
  margin-bottom: 5px;
}

.pagination-div {
  display: flex;
}
</style>