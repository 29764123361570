class Calculations {

    static calculateUnitPrice(unitCost, markup) {
        if(unitCost === null || markup === null) { return; }
        if(typeof unitCost !== 'number' || typeof markup !== 'number') { return; }

        let totalMarkup = this.calculateMarkup(unitCost, markup);
        let totalTax = this.calculateTax(unitCost);

        let totalCost = unitCost + totalTax + totalMarkup;

        return Number(totalCost.toFixed(2));
    }

    static calculateTax(unitCost) {
        let tax =  0.07 * unitCost;
        return tax;
    }

    // Should only be used with a receipt.
    static calculateShipping(unitCost, quantity, receiptTotal, shippingTotal) {
        if(shippingTotal === 0) { return 0; }
        // Get weight for shipping.
        let totalUnitCost = unitCost * quantity;
        let weightFromTotal = (totalUnitCost / receiptTotal) * 100;
        let weightFromShipping = (weightFromTotal/100) * shippingTotal;

        // Calculate Item
        let weightUnitCost = (unitCost/totalUnitCost) * 100;
        let weightUnitCostShipping = (weightUnitCost/100) * weightFromShipping;
        return Number(weightUnitCostShipping.toFixed(2));
    }

    static calculateMarkup(unitCost, markup) {
        const percent = markup / 100;
        const amountFromPercent = percent * unitCost;
        return amountFromPercent;
    }
}

export default Calculations;

// The same as .toFixed except it returns a number instead of a string.
Number.prototype.toFixedNumber = function(digits, base) {
    let pow = Math.pow(base||10, digits);
    return Math.round(this * pow) / pow;
}