class subItemType {

    static networkTypes = [
        'ROUTER',
        'SWITCH',
        'ACCESS_POINT',
        'FIREWALL',
        'HUB',
        'IP_CAMERA'
    ];

    static computerTypes = [
        'DESKTOP',
        'LAPTOP'
    ];

    static mobileTypes = [
        'TABLET',
        'PHONE'
    ];

    static computerPartsTypes = [
        'CPU',
        'RAM',
        'MOTHERBOARD',
        'GPU',
        'NETWORK_CARD',
        'HARD_DRIVE',
        'SOLID_STATE_DRIVE',
        'POWER_SUPPLY'
    ];

    static softwareTypes = [
        'LICENSE'
    ];

    static cableTypes = [
        'CHARGER',
        'POWER',
        'SERIAL',
        'USB',
        'ETHERNET_CABLE',
        'FIBER_OPTIC'
    ];

    static adapterTypes = [
        'DISPLAY',
        'NETWORK'
    ];

    static accessoriesTypes = [
        'MOUSE',
        'KEYBOARD',
        'MONITOR',
        'FLASH_DRIVE',
        'PRINTER',
        'HEADPHONES',
        'EXTERNAL_DRIVE',
        'WEB_CAMERA'
    ];

    static getSubItemTypes(itemType) {
        let subItemTypes = [];
        switch(itemType) {
            case 'NETWORK_EQUIPMENT':
                subItemTypes = this.networkTypes;
                break;
            case 'COMPUTER':
                subItemTypes = this.computerTypes;
                break;
            case 'MOBILE':
                subItemTypes = this.mobileTypes;
                break;
            case 'COMPUTER_PARTS':
                subItemTypes = this.computerPartsTypes;
                break;
            case 'SOFTWARE':
                subItemTypes = this.softwareTypes;
                break;
            case 'CABLE':
                subItemTypes = this.cableTypes;
                break;
            case 'ADAPTER':
                subItemTypes = this.adapterTypes;
                break;
            case 'ACCESSORIES':
                subItemTypes = this.accessoriesTypes;
                break;
            case 'OTHER':
                break;
        }

        if(!subItemTypes.includes('OTHER')) {
            subItemTypes.push('OTHER');
        }

        return subItemTypes;
    }
}

export default subItemType;