<template>
  <!-- Item Name -->
  <td class="main-td">
    <input class="main-textbox" type="text" placeholder="Item Name" maxlength="100" v-model="this.item.itemName" disabled="true"/>
  </td>

  <!-- Item Cost -->
  <td class="main-td">
    $<input class="main-textbox" type="number" placeholder="Unit Cost" min="0.00" step="0.25" v-model="this.item.unitCost" disabled="true"/>
  </td>

  <!-- Return Button -->
  <td class="main-td">
    <button class="green-button details-buttons" @click.prevent="this.handleReturn()">Return</button>
  </td>
</template>

<script>

export default {
  name: 'ReturnCreationTableItem',
  props: {
    rawItem: Object
  },
  emits: [ 'handleReturn' ],
  data() {
    return {
      item: this.rawItem
    }
  },
  methods: {
    handleReturn() {
      let clone = { ...this.item };
      this.$emit('handleReturn', clone);
    }
  }
}
</script>

<style scoped>

.details-buttons {
  margin-top: 10px;
  margin-bottom: 10px;
  height: 50px;
}
</style>