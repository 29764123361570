class JWTUtil {

    //TODO: Doesn't exactly work. Was built for logout feature.

    static clearJWTs() {
        let domain = document.domain;
        let path = '/';

        document.cookie = "wowinventory-access-token" + "=" +
            ((path) ? ";path=" + path : "") +
            ((domain) ? ";domain=" + domain : "") +
            ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
    }
}

export default JWTUtil;