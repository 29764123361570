<template>
  <div>
    <!-- Vendor -->
    <div class="row-content">
      <label class="main-label">Vendor</label>
      <input class="main-textbox input-box" type="text" maxlength="50" placeholder="Vendor" v-model="this.receipt.vendor"/>
    </div>

    <!-- Date Purchased -->
    <div class="row-content">
      <label class="main-label">Date Purchased</label>
      <input class="main-date-selector input-box" type="date" min="2020-01-01" v-model="this.receipt.datePurchased"/>
    </div>

    <!-- Shipping -->
    <div class="row-content">
      <label class="main-label">Shipping Amount</label>
      <input class="main-textbox input-box" type="number" maxlength="50" placeholder="Shipping" min="0.00" step="0.25" v-model="this.receipt.shippingAmount" @input="this.updateTotalCost()"/>
    </div>

    <!-- Tax -->
    <div class="row-content">
      <label class="main-label">Tax Amount</label>
      <input class="main-textbox input-box" type="number" maxlength="50" placeholder="Tax" min="0.00" step="0.25" v-model="this.receipt.taxAmount" @input="this.updateTotalCost()"/>
    </div>

    <!-- Coupon -->
    <div class="row-content">
      <label class="main-label">Coupon</label>
      <input class="main-textbox input-box" type="number" maxlength="50" placeholder="Coupon" min="0.00" step="0.25" v-model="this.receipt.coupon" @input="this.updateTotalCost()"/>
    </div>

    <!-- Upload Receipt File -->
    <div class="row-content">
      <input type="file" name="attachment" multiple="false" @change="this.addedFile"/>
    </div>
  </div>

  <!-- Item Creation Section -->
  <div class="item-creation-div">
    <table class="main-table">
      <thead>
        <tr>
          <th class="main-th">
            <button class="dark-green-button details-buttons" @click.prevent="this.addItem()">Add Item</button>
          </th>
          <th class="main-th">Item Name</th>
          <th class="main-th">Item-Type</th>
          <th class="main-th">Sub-Item Type</th>
          <th class="main-th">Unit Cost</th>
          <th class="main-th">Item Status</th>
          <th class="main-th">Quantity</th>
          <th class="main-th">Receipt Price</th>
        </tr>
      </thead>
      <tbody>
        <tr class="receipt-creation-tr" v-for="item in items" :key="item">
          <ReceiptCreationTableItem :raw-item="item" @updateCost="this.updateTotalCost()" @remove="this.removeItem"/>
        </tr>
      </tbody>
    </table>
  </div>

  <label class="main-label total-amount-label">Total Amount: ${{ this.totalCost }}</label>
</template>

<script>
import ReceiptCreationTableItem from '@/components/ReceiptCreationTableItem';

export default {
  name: 'ReceiptDetails',
  components: { ReceiptCreationTableItem },
  props: {
    receiptData: Object,
    itemsData: Array,
  },
  data() {
    return {
      rawReceipt: {
        vendor: '',
        datePurchased: null,
        shippingAmount: '',
        taxAmount: '',
        coupon: ''
      },
      rawItems: [],
      rawTotalCost: 0,
      file: ''
    }
  },
  computed: {
    receipt() {
      if(this.receiptData == null) {
        return this.rawReceipt;
      } else {
        return this.receiptData;
      }
    },
    items() {
      if(this.itemsData == null || this.itemsData.length === 0) {
        return this.rawItems;
      } else {
        return this.itemsData;
      }
    },
    totalCost() {
      if(this.items == null) {
        return 0;
      } else {
        this.updateTotalCost();
        return this.rawTotalCost;
      }
    }
  },
  methods: {
    addItem() {
      let item = {
        itemName: '',
        itemType: '',
        subItemType: '',
        unitCost: 0,
        markup: 40, // Default Markup
        supplies: false,
        quantity: 1
      }
      this.items.push(item);
    },
    addedFile(event) {
      this.file = event.target.files[0];
    },
    removeItem(item) {
      let index = this.items.indexOf(item);
      this.items.splice(index, 1);
    },
    updateTotalCost() {
      this.rawTotalCost = this.calculateReceiptTotalCost();
    },
    calculateReceiptTotalCost() {
      let sum = 0;
      this.items.forEach(item => {
        let cost = item.unitCost;
        let quantity = item.quantity;
        let money = cost * quantity;
        let pennies = money * 100;
        let lineItemTotal = Math.ceil(pennies) / 100;
        sum += lineItemTotal;
      });

      let shippingAmount = this.receipt.shippingAmount;
      let taxAmount = this.receipt.taxAmount;
      let coupon = this.receipt.coupon;

      if(typeof shippingAmount === 'string') {
        shippingAmount = 0.0;
      }

      if(typeof taxAmount === 'string') {
        taxAmount = 0.0;
      }

      if(typeof coupon === 'string') {
        coupon = 0.0;
      }

      // Force Coupon to be a negative number!
      if(coupon > 0) { coupon *= -1; }

      let total = (shippingAmount + taxAmount + coupon) + sum;

      if(typeof total === 'string') {
        total = parseFloat(total);
      }

      return total.toFixed(2);
    }
  }
}
</script>

<style scoped>

.row-content {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 5px;
  margin-bottom: 10px;
}

.input-box {
  width: 95%;
}

.item-creation-div {
  display: flex;
  gap: 5px;
  overflow-y: scroll;
  max-height: 600px;
}

.receipt-creation-tr:nth-child(even) {
  background-color: #e8e8e8;
}

.receipt-creation-tr:nth-child(odd) {
  background-color: #f5f5f5;
}

.total-amount-label {
  margin-top: 10px;
}

.details-buttons {
  margin-top: 10px;
  margin-bottom: 10px;
  height: 50px;
}

</style>