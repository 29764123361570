class TemplateChecker {

    static isValidTemplate(template) {
        if(template.itemName === null || template.itemName.length === 0) {
            alert('Item Name is Empty!')
            return false;
        }

        if(template.itemType === null || template.itemType.length === 0) {
            alert('Item Type is Empty!')
            return false;
        }

        if(template.subItemType === null || template.subItemType.length === 0) {
            alert('Sub Item Type is Empty!')
            return false;
        }

        return true;
    }
}

export default TemplateChecker;