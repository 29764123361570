<template>
  <div>
    <!-- Item Name -->
    <div class="row-content">
      <label class="main-label">Item Name</label>
      <input class="main-textbox input-box" type="text" maxlength="50" placeholder="Item Name" v-model="this.template.itemName"/>
    </div>

    <!-- Item Type -->
    <div class="row-content">
      <label class="main-label">Item Type</label>
      <select class="main-select-box input-box" v-model="this.template.itemType">
        <option v-for="itemType in itemTypes" :key="itemType" :value="itemType">{{ itemType }}</option>
      </select>
    </div>

    <!-- Sub Item Type -->
    <div class="row-content">
      <label class="main-label">Sub-Item Type</label>
      <select class="main-select-box input-box" v-model="this.template.subItemType">
        <option v-for="subItemType in subItemTypes" :key="subItemType" :value="subItemType">{{ subItemType }}</option>
      </select>
    </div>

    <!-- Unit Cost -->
    <div class="row-content">
      <label class="main-label">Unit Cost</label>
      <input class="main-textbox input-box" type="number" placeholder="Unit Cost" min="0.00" step="0.25" v-model="this.template.unitCost"/>
    </div>

    <!-- Markup -->
    <div class="row-content">
      <label class="main-label">Markup</label>
      <input class="main-textbox input-box" type="number" placeholder="Markup" min="0.00" step="0.25" v-model="this.template.markup"/>
    </div>
  </div>
</template>

<script>
import { itemTypes } from '@/types/ItemType';
import subItemTypes from '@/types/SubItemType';

export default {
  name: 'TemplateDetails',
  props: {
    templateData: Object
  },
  data() {
    return {
      itemTypes: itemTypes,
      subItemTypes: [],
      rawTemplate: {
        itemName: null,
        itemType: null,
        subItemType: null,
        unitCost: '',
        markup: ''
      }
    }
  },
  updated() {
    if(this.template.itemType === null) { return; }
    this.updateSubItemTypes();
  },
  computed: {
    template() {
      if(this.templateData == null) {
        return this.rawTemplate;
      } else {
        return this.templateData;
      }
    }
  },
  methods: {
    updateSubItemTypes() {
      let itemType = this.template.itemType;
      this.subItemTypes = subItemTypes.getSubItemTypes(itemType)
    }
  }
}
</script>

<style scoped>

.row-content {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 10px;
}

.input-box {
  width: 95%;
}
</style>