class ItemRemapper {

    static remap(item) {
        const map = new Map();

        if(item.uniqueId !== null) { map.set('uniqueId', item.uniqueId); }

        map.set('itemName', item.itemName);
        map.set('itemType', item.itemType);
        map.set('subItemType', item.subItemType);
        map.set('unitCost', item.unitCost);
        map.set('markup', item.markup);
        map.set('unitPrice', item.unitPrice);
        map.set('ticketNumber', item.ticketNumber);
        map.set('chargeId', item.chargeId);
        map.set('itemStatus', item.itemStatus);
        map.set('itemLocation', item.itemLocation);
        map.set('dateSold', item.dateSold);
        map.set('description', item.description);
        map.set('notes', item.notes);

        let data = {};

        map.forEach(function(value, key) {
            data[key] = value;
        });

        return data;
    }
}

export default ItemRemapper;
