<template>
  <main class="popup-background">
    <section class="reports-popup">
      <div class="content-div">
        <label class="main-label">Specified Date</label>
        <input class="main-date-selector input-box" type="date" min="2020-01-01" v-model="this.requestedData"/>
      </div>
      <div class="footer-div">
        <button class="green-button" @click.prevent="$emit('close')">Close</button>
        <button class="green-button" @click.prevent="this.downloadReport()">Download</button>
      </div>
    </section>
  </main>
</template>

<script>
import ApiCaller from '@/utilities/apiCaller';

export default {
  name: 'ReportsPopup',
  emits: [ 'close' ],
  data() {
    return {
      requestedData: null
    }
  },
  methods: {
    async downloadReport() {
      const doc = await ApiCaller.fetchFile(`/api/v1/reports/inventory/download?specifiedDate=${this.requestedData}`);

      const download = document.createElement('a');
      const file = new Blob([doc], { type: 'text/csv'});

      download.href = URL.createObjectURL(file);

      download.download = `Report-${this.requestedData}.csv`;
      download.click();
      URL.revokeObjectURL(download.href);
    }
  }
}
</script>

<style scoped>

.reports-popup {
  display: grid;
  justify-content: center;
  background: lightgray;
  min-width: 500px;
  min-height: 200px;
  border-radius: 5px;
}

.content-div {
  width: 300px;
  margin-top: 20px;
}

.footer-div {
  display: flex;
  margin-top: 5px;
  margin-bottom: 5px;
  justify-content: center;
  gap: 10px;
  height: 50px;
}

</style>