<template>
  <main class="main-page">
    <label class="main-title">Item Creation</label>
    <ItemDetails ref="itemDetails"/>
    <button class="green-button create-button" @click.prevent="this.create()">Create Item</button>
  </main>
</template>

<script>
import ApiCaller from '@/utilities/apiCaller';
import Calculations from '@/utilities/calculations';
import ItemDetails from '@/components/details/ItemDetails';
import ItemChecker from '@/utilities/itemChecker';
import TicketChargeUtil from '@/utilities/ticketChargeUtil';
import router from '@/router';

export default {
  name: 'ItemCreateView',
  components: { ItemDetails },
  data() {
    return {
      item: {},
      ticket: null
    }
  },
  methods: {
    async create() {
      this.item = this.$refs.itemDetails.item;
      this.ticket = this.$refs.itemDetails.ticket;

      if(ItemChecker.isValidItem(this.item) === false) { return; }

      //Markup Calculations
      this.item.unitPrice = Calculations.calculateUnitPrice(this.item.unitCost, this.item.markup);

      if(this.ticket !== null) {
        let chargeId = await TicketChargeUtil.getChargeId(this.item, this.ticket);
        this.item.chargeId = chargeId;
        this.item.itemStatus = 'CHARGED';
      }

      await this.sendData(this.item);
    },
    async sendData(data) {
      const uri = '/api/v1/items/create';
      const stringifyData = JSON.stringify(data);
      let response = await ApiCaller.sendData(uri, stringifyData, 'POST');

      if(response.includes('Success')) {
        await router.push({path: '/inventory'});
      }
    }
  }
}
</script>

<style scoped>

.main-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.create-button {
  margin-top: 10px;
  margin-bottom: 10px;
  height: 50px;
  width: 130px;
}
</style>