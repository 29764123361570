<template>
  <main class="main-page">
    <label class="main-title">Receipt Creation</label>
    <ReceiptDetails ref="receiptDetails"/>
    <button class="green-button create-button" @click.prevent="this.createReceipt()">Create Receipt</button>
  </main>
</template>

<script>
import ApiCaller from '@/utilities/apiCaller';
import Calculations from '@/utilities/calculations';
import ReceiptRemapper from '@/utilities/receiptRemapper';
import ReceiptDetails from '@/components/details/ReceiptDetails';
import router from '@/router';
import ReceiptChecker from '@/utilities/receiptChecker';
import ItemChecker from '@/utilities/itemChecker';

export default {
  name: "ReceiptCreateView",
  components: { ReceiptDetails },
  data() {
    return {
      receipt: {},
      items: [],
    }
  },
  methods: {
    createReceipt() {
      // Verify the data
      this.receipt = this.$refs.receiptDetails.receipt;

      if(!ReceiptChecker.isValidReceipt(this.receipt)) { return; }
      this.items = this.$refs.receiptDetails.items;

      if(typeof this.receipt.shippingAmount === 'string') { this.receipt.shippingAmount = 0.0; }
      if(typeof this.receipt.taxAmount === 'string') { this.receipt.taxAmount = 0.0; }
      if(typeof this.receipt.coupon === 'string') { this.receipt.coupon = 0.0; }

      if(this.items.length === 0) {
        alert('There are no items!')
        return;
      }

      if(!ItemChecker.isValidItemsOnReceipt(this.items)) { return; }

      // Calculate the Price.
      let totalCost = this.$refs.receiptDetails.totalCost - this.receipt.shippingAmount;
      this.items.forEach(item => {
        let unitPrice = Calculations.calculateUnitPrice(item.unitCost, item.markup) + Calculations.calculateShipping(item.unitCost, item.quantity, totalCost, this.receipt.shippingAmount);
        item['unitPrice'] = Number(unitPrice.toFixed(2));
      });

      let data = ReceiptRemapper.remap(this.receipt, this.items);
      this.sendData(data);
    },
    async sendData(data) {
      const uri = '/api/v1/receipts/create';

      const stringifyData = JSON.stringify(data);
      let response = await ApiCaller.sendData(uri, stringifyData, 'POST');

      if(response.includes('Success')) {
        await router.push({path: '/receipts'});
      }
    }
  }
}
</script>

<style scoped>

.main-page {
  display: flex;
  flex-direction: column;
  align-items: safe center;
}

.create-button {
  margin-top: 10px;
  margin-bottom: 10px;
  height: 50px;
}

</style>