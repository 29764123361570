<template>
  <main class="main-page">
    <label class="main-title">Return Creation</label>
    <ReturnDetails ref="returnDetails"
                   :receipt-data="this.receipt"
                   :items-data="this.items"
                   @addReturnItem="this.addReturnItem"/>

    <div class="spacer"></div>

    <div class="">
      <table class="main-table">
        <thead>
        <tr>
          <th class="main-th">Item Name</th>
          <th class="main-th">Return Amount</th>
        </tr>
        </thead>
        <tbody>
        <tr class="return-creation-tr" v-for="preReturnItem in preReturnItems" :key="preReturnItem">
          <!-- Item Name -->
          <td class="main-td">
            <input class="main-textbox" type="text" placeholder="Item Name" maxlength="100" v-model="preReturnItem.itemName" disabled="true"/>
          </td>

          <!-- Return Amount -->
          <td class="main-td">
            $<input class="main-textbox" type="number" placeholder="Unit Cost" min="0.00" step="0.25" v-model="preReturnItem.amount"/>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <button class="green-button create-button" @click.prevent="this.create()">Create Return</button>
  </main>
</template>

<script>
import ReturnDetails from "@/components/details/ReturnDetails.vue";
import ApiCaller from "@/utilities/apiCaller";
import router from "@/router";
import { useRoute } from "vue-router";

export default {
  name: "ReturnCreation",
  components: {
    ReturnDetails
  },
  data() {
    return {
      uniqueId: useRoute().params.uniqueId.toString(),
      receiptData: {},
      itemsData: [],
      preReturnItems: []
    }
  },
  created() {
    this.fetchData();
  },
  computed: {
    receipt() {
      return this.receiptData;
    },
    items() {
      return this.itemsData;
    }
  },
  methods: {
    async fetchData() {
      const receiptUri = '/api/v1/receipts/?uniqueId=' + this.uniqueId;
      let receiptJsonData = await ApiCaller.fetchData(receiptUri);

      if(receiptJsonData === '[]') {
        await router.push({path: '/receipts'});
        return;
      }

      this.receiptData = JSON.parse(receiptJsonData)[0];

      const itemsUri = '/api/v1/items/?receiptId=' + this.uniqueId;
      let itemsJsonData = await ApiCaller.fetchData(itemsUri);

      this.itemsData = JSON.parse(itemsJsonData);
    },
    addReturnItem(item) {
      let indexToRemove = this.itemsData.findIndex(item => item.uniqueId === item.uniqueId);

      if (indexToRemove !== -1) {
        this.itemsData.splice(indexToRemove, 1);
      }

      this.preReturnItems.push(item);
    },
    async create() {
      let data = JSON.parse(JSON.stringify(this.preReturnItems))
      await this.sendData(data);
    },
    async sendData(data) {
      const uri = '/api/v1/returns/create';
      let response = await ApiCaller.sendData(uri, JSON.stringify(data), 'POST');

      if(response.includes('Success')) {
        await router.push({path: '/receipts'});
      }
    }
  }
}
</script>

<style scoped>

.main-page {
  display: flex;
  flex-direction: column;
  align-items: safe center;
}

.spacer {
  margin-top: 10px;
  margin-bottom: 10px;
  height: 25px;
}

.return-creation-tr:nth-child(even) {
  background-color: #e8e8e8;
}

.return-creation-tr:nth-child(odd) {
  background-color: #f5f5f5;
}

.create-button {
  margin-top: 10px;
  margin-bottom: 10px;
  height: 50px;
}
</style>