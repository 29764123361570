import { createApp } from 'vue';
import App from './App.vue';
import Router from './router';

// CSS Styles
import '@/styles/button_style.css';
import '@/styles/colors.css';
import '@/styles/date_selector_style.css';
import '@/styles/hamburger_menu_style.css';
import '@/styles/label_style.css';
import '@/styles/popup_style.css';
import '@/styles/router_transitions.css';
import '@/styles/select_box_style.css';
import '@/styles/table_style.css';
import '@/styles/text_box_style.css';

const app = createApp(App);

app.use(Router);
app.mount('#index');