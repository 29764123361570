<template>
  <!-- Current Receipt Section -->
  <div class="">
    <table class="main-table">
      <thead>
        <tr>
          <th class="main-th">Item Name</th>
          <th class="main-th">Item Cost</th>
          <th class="main-th"></th>
        </tr>
      </thead>
      <tbody>
        <tr class="return-creation-tr" v-for="item in items" :key="item">
          <ReturnCreationTableItem :raw-item="item" @handleReturn="this.addItem"/>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import ReturnCreationTableItem from "@/components/ReturnCreationTableItem.vue";

export default {
  name: 'ReturnDetails',
  components: { ReturnCreationTableItem },
  props: {
    receiptData: Object,
    itemsData: Array
  },
  emits: [ 'addReturnItem' ],
  computed: {
    receipt() {
      return this.receiptData
    },
    items() {
      return this.itemsData
    }
  },
  methods: {
    addItem(item) {
      let preReturnItem = {
        itemId: item.uniqueId,
        itemName: item.itemName,
        amount: 0
      }

      this.$emit('addReturnItem', preReturnItem);
    }
  }
}
</script>

<style scoped>

.return-creation-tr:nth-child(even) {
  background-color: #e8e8e8;
}

.return-creation-tr:nth-child(odd) {
  background-color: #f5f5f5;
}
</style>