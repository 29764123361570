class ReceiptChecker {

    static isValidReceipt(receipt) {
        if(receipt.vendor === null || receipt.vendor.length === 0) {
            alert("Vendor is empty!")
            return false;
        }

        if(receipt.datePurchased === null) {
            alert("Date of purchase is empty!")
            return false;
        }

        return true;
    }

}

export default ReceiptChecker;