<template>
  <!-- Remove Button -->
  <td class="main-td">
    <button class="green-button details-buttons" @click.prevent="this.removeItem(this.item)">Remove</button>
  </td>

  <!-- Item Name -->
  <td class="main-td">
    <input class="main-textbox" type="text" placeholder="Item Name" maxlength="100" v-model="this.item.itemName"/>
  </td>

  <!-- Item Type -->
  <td class="main-td">
    <select class="main-select-box type-input-box" v-model="this.item.itemType">
      <option v-for="itemType in itemTypes" :key="itemType" :value="itemType">{{ itemType }}</option>
    </select>
  </td>

  <!-- Sub Item Type -->
  <td class="main-td">
    <select class="main-select-box type-input-box" v-model="this.item.subItemType">
      <option v-for="subItemType in subItemTypes" :key="subItemType" :value="subItemType">{{ subItemType }}</option>
    </select>
  </td>

  <!-- Unit Cost -->
  <td class="main-td">
    $<input class="main-textbox" type="number" placeholder="Unit Cost" min="0.00" step="0.25" v-model="this.item.unitCost" @input="this.updateCost()"/>
  </td>

  <!-- Item Status -->
  <td class="main-td">
    Supplies: <input type="checkbox" v-model="this.item.supplies"/>
  </td>

  <!-- Quantity -->
  <td class="main-td">
    x<input class="main-textbox table-input-box" type="number" placeholder="Quantity" min="1" step="1" v-model="this.item.quantity" @input="this.updateCost()"/>
  </td>

  <!-- Receipt Price -->
  <td class="main-td">${{ this.itemCost }}</td>
</template>

<script>
import {itemTypes} from '@/types/ItemType';
import subItemTypes from '@/types/SubItemType';

export default {
  name: "ReceiptCreationTableItem",
  props: {
    rawItem: Object,
  },
  emits: [ 'updateCost', 'remove' ],
  data() {
    return {
      itemTypes: itemTypes
    }
  },
  computed: {
    item() {
      return this.rawItem;
    },
    itemCost() {
      return this.calculateReceiptItemCost(this.item);
    },
    subItemTypes() {
      if(this.item.itemType === null) {
        return [];
      } else {
        let itemType = this.item.itemType;
        return subItemTypes.getSubItemTypes(itemType);
      }
    }
  },
  methods: {
    removeItem(item) {
      this.$emit('remove', item);
    },
    calculateReceiptItemCost(item) { // Calculates individual line item.
      let cost = item.unitCost;
      let quantity = item.quantity;
      let money = cost * quantity;
      let pennies = money * 100;
      let sum = Math.ceil(pennies) / 100;
      return sum.toFixed(2);
    },
    updateCost() {
      this.$emit('updateCost');
    }
  }
}
</script>

<style scoped>

.type-input-box {
  min-width: 300px;
}

.table-input-box {
  max-width: 70px;
}

.details-buttons {
  margin-top: 10px;
  margin-bottom: 10px;
  height: 50px;
}

</style>