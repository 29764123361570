import ApiCaller from '@/utilities/apiCaller';

class TicketChargeUtil {

    static async getChargeId(item, ticket) {
        const autoTaskUri = '/autotask/createTicketCharge';

        let billingCodeId;

        if(item.itemType === 'SOFTWARE') {
            billingCodeId = ticket.company.taxExempt ? 29706543 : 29706542;
        } else {
            billingCodeId = ticket.company.taxExempt ? 29706544 : 29706545;
        }

        // Swapped itemName & description around to hide the model number from the customer.

        let ticketCharge = {
            name: item.itemType.replaceAll("_", " "),
            chargeType: 1,
            datePurchased: item.datePurchased,
            unitQuantity: 1.0,
            unitCost: item.unitCost,
            unitPrice: item.unitPrice,
            billingCodeId: billingCodeId,
            ticketId: ticket.id,
            description: item.description,
            notes: item.notes
        };

        let response = await ApiCaller.sendData(autoTaskUri, JSON.stringify(ticketCharge), 'POST');
        let chargeId = JSON.parse(response);

        return chargeId.itemId;
    }
}

export default TicketChargeUtil;