class ReceiptRemapper {

    static remap(receipt, items) {
        const map = new Map();

        map.set("Receipt-Reference", "Wowlinx Inventory");
        map.set("Vendor", receipt.vendor);

        if(typeof receipt.shippingAmount !== 'number') { receipt.shippingAmount = 0; }
        if(typeof receipt.taxAmount!== 'number') { receipt.taxAmount = 0; }

        map.set("ShippingAmount", receipt.shippingAmount);
        map.set("TaxAmount", receipt.taxAmount);
        map.set("Coupon", receipt.coupon);

        map.set("DatePurchased", receipt.datePurchased);
        map.set("Items", items);

        let object = {};

        map.forEach(function(value, key) {
            object[key] = value;
        });

        return object;
    }

}

export default ReceiptRemapper;