<template>
  <TableController :columns="this.columns" :items="this.items" :pages="this.pages" :page="this.currentPage" :table="'InventoryTable'"
                   @header-selected="this.headerSelected" @item-selected="this.itemSelected"
                   @search="this.search" @show-limit-updated="this.showLimit" @paginate="this.pagination"
                   v-if="this.loaded">

    <template v-slot:TableControlsSlot>
      <div class="table-controls-slot-div">
        <label class="main-label">Completed Items: </label>
        <input type="checkbox" v-model="this.includeCompleted" @change="this.updateItems()"/>
      </div>
      <div class="table-control-slot-buttons">
        <button class="green-button" :disabled="true" @click.prevent="this.navigateToCreate()">Add New Item</button>
      </div>
    </template>
  </TableController>
</template>

<script>
import ApiCaller from '@/utilities/apiCaller';
import TableController from '@/controllers/TableController';
import router from '@/router';

export default {
  name: 'InventoryComponent',
  components: { TableController },
  data() {
    return {
      columns: [
          'Item Name',
          'Item Type',
          'Sub Item Type',
          'Unit Cost',
          'Unit Price',
          'Ticket Number',
          'Item Status',
          'Item Location',
          'Date Purchased',
          'Date Sold',
          'Vendor',
          'Invoice Number'
      ],
      rawItems: [],
      totalItems: 0,
      rawPages: 0,
      searchInput: '',
      column: 'itemname',
      limit: 10,
      page: 1,
      includeCompleted: false,
      loaded: false
    }
  },
  computed: {
    items() {
      return this.rawItems || [];
    },
    pages() {
      return this.rawPages || 0;
    },
    currentPage() {
      return this.page || 1;
    }
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      await this.updateItems();
      this.loaded = true;
    },
    search(searchInput) {
      this.searchInput = searchInput;
      this.page = 1;
      this.updateItems();
    },
    showLimit(limit) {
      this.limit = limit;
      this.page = 1;
      this.updateItems();
    },
    pagination(page) {
      this.page = page;
      this.updateItems();
    },
    async updateItems() {
      let itemsJsonData = [];

      if(this.searchInput.length == 0) {
        itemsJsonData = await ApiCaller.fetchData(`/api/v1/items/filtered/?column=${ this.column }&limit=${ this.limit }&page=${ this.currentPage }&includeCompleted=${ this.includeCompleted }`);
        this.totalItems = await ApiCaller.fetchData(`/api/v1/items/total?includeCompleted=${ this.includeCompleted }`);
      } else {
        itemsJsonData = await ApiCaller.fetchData(`/api/v1/items/search/?searchInput=${ this.searchInput }&column=${ this.column }&limit=${ this.limit }&page=${ this.currentPage }&includeCompleted=${ this.includeCompleted }`);
        this.totalItems = await ApiCaller.fetchData(`/api/v1/items/searchCount/?searchInput=${ this.searchInput }&includeCompleted=${ this.includeCompleted }`);
      }
      this.rawItems = JSON.parse(itemsJsonData);
      this.rawPages = Math.ceil(this.totalItems / this.limit);
    },
    headerSelected(header) {
      this.column = header.replaceAll(' ', '').toLowerCase();
      this.updateItems();
    },
    itemSelected(uniqueId) {
      router.push({ path: 'inventory/details/' + uniqueId });
    },
    navigateToCreate() {
      router.push({ path: 'inventory/create'});
    }
  }
}
</script>

<style scoped>

.table-controls-slot-div {
  display: flex;
  justify-content: center;
  line-height: 3;
}

.table-control-slot-buttons {
  display: flex;
  gap: 10px;
}

</style>