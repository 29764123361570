<template>
  <main class="main-page">
    <ConfirmationPopup v-if="this.confirmationPopupEnabled" @confirm="this.remove()" @cancel="this.confirmationPopupEnabled=false" :message="'Are you sure you want to remove this template?'"/>
    <label class="main-title">Template Details</label>
    <TemplateDetails ref="templateDetails" :template-data="this.template"/>
    <div class="button-section">
      <button class="green-button details-buttons" @click.prevent="this.update()">Update</button>
      <button class="green-button details-buttons" @click.prevent="this.confirmationPopupEnabled=true">Remove</button>
    </div>
  </main>
</template>

<script>
import { useRoute } from 'vue-router';
import ApiCaller from '@/utilities/apiCaller';
import TemplateChecker from '@/utilities/templateChecker';
import TemplateDetails from '@/components/details/TemplateDetails';
import ConfirmationPopup from '@/components/popups/ConfirmationPopup';
import router from '@/router';

export default {
  name: 'TemplateDetailsView',
  components: { ConfirmationPopup, TemplateDetails },
  data() {
    return {
      uniqueId: useRoute().params.uniqueId.toString(),
      template: {},
      confirmationPopupEnabled: false
    }
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      const uri = '/api/v1/templates/?uniqueId=' + this.uniqueId;
      let jsonData = await ApiCaller.fetchData(uri);

      if(jsonData === '[]') {
        await router.push({path: '/templates'});
        return;
      }

      this.template = JSON.parse(jsonData)[0];
    },
    async update() {
      this.template = this.$refs.templateDetails.template;

      if(TemplateChecker.isValidTemplate(this.template) === false) { return; }

      const uri = '/api/v1/templates/update';

      const stringifyData = JSON.stringify(this.template);

      let response = await ApiCaller.sendData(uri, stringifyData, 'PUT');

      if(response.includes('Success')) {
        await router.push({path: '/templates'});
      }
    },
    async remove() {
      this.confirmationPopupEnabled = false;
      const uri = '/api/v1/templates/delete/uniqueId=' + this.uniqueId;
      let response = await ApiCaller.deleteData(uri);

      if(response.includes('Success')) {
        await router.push({path: '/templates'});
      }
    }
  }
}
</script>

<style scoped>

.main-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button-section {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  gap: 10px;
}

.details-buttons {
  margin-top: 10px;
  margin-bottom: 10px;
  height: 50px;
}
</style>