export const itemTypes = [
    'NETWORK_EQUIPMENT',
    'COMPUTER',
    'MOBILE',
    'COMPUTER_PARTS',
    'SOFTWARE',
    'CABLE',
    'ADAPTER',
    'ACCESSORIES',
    'OTHER'
];