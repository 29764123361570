<template>
  <tr class="main-tr" v-for="td in tableData" :key="td">
    <td class="main-td" @click.prevent="this.itemSelected(td.uniqueId)" @contextmenu.prevent="itemSelectedNewTab(td.uniqueId)">{{ td.vendor }}</td>
    <td class="main-td" @click.prevent="this.itemSelected(td.uniqueId)" @contextmenu.prevent="itemSelectedNewTab(td.uniqueId)">{{ td.datePurchased }}</td>
    <td class="main-td" @click.prevent="this.itemSelected(td.uniqueId)" @contextmenu.prevent="itemSelectedNewTab(td.uniqueId)">{{ td.dateCreated }}</td>
    <td class="main-td" @click.prevent="this.itemSelected(td.uniqueId)" @contextmenu.prevent="itemSelectedNewTab(td.uniqueId)">${{ td.totalCost }}</td>
    <td class="main-td">
      <a target="_blank" rel="noopener noreferrer" :href="'https://go.xero.com/Bank/ViewTransaction.aspx?bankTransactionID=' + td.bankTransactionId" v-if="td.bankTransactionId != null">Xero</a>
    </td>
  </tr>
</template>

<script>

export default {
  name: 'ReceiptTable',
  props: {
    items: Array,
  },
  emits: ['item-selected'],
  computed: {
    tableData() {
      return this.items || []
    }
  },
  methods: {
    itemSelected(uniqueId) {
      this.$emit('item-selected', uniqueId);
    },
    itemSelectedNewTab(uniqueId) {
      window.open('/receipts/details/' + uniqueId);
    }
  }
}
</script>

<style scoped>

</style>