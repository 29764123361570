<template>
  <main class="main-page">
    <label class="main-title">Template Creation</label>
    <TemplateDetails ref="templateDetails"/>
    <button class="green-button create-button" @click.prevent="this.createTemplate()">Create Template</button>
  </main>
</template>

<script>
import ApiCaller from '@/utilities/apiCaller';
import TemplateDetails from '@/components/details/TemplateDetails';
import router from '@/router';
import TemplateChecker from '@/utilities/templateChecker';

export default {
  name: 'TemplateCreateView',
  components: { TemplateDetails },
  data() {
    return {
      template: {}
    }
  },
  methods: {
    createTemplate() {
      this.template = this.$refs.templateDetails.template;
      if(TemplateChecker.isValidTemplate(this.template) === false) { return; }
      this.sendData(this.template);
    },
    async sendData(data) {
      const uri = '/api/v1/templates/create';
      const stringifyData = JSON.stringify(data);
      let response = await ApiCaller.sendData(uri, stringifyData, 'POST');

      if(response.includes('Success')) {
        await router.push({path: '/templates'});
      }
    }
  }
}
</script>

<style scoped>

.main-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.create-button {
  margin-top: 10px;
  margin-bottom: 10px;
  height: 50px;
}
</style>