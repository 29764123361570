<template>
  <main class="popup-background">
    <section class="confirmation-popup">
      <div class="message-div">
        <label class="main-label">{{ this.message }}</label>
      </div>
      <div class="confirmation-buttons">
        <button class="green-button confirmation-button" @click.prevent="$emit('confirm')">Yes</button>
        <button class="green-button confirmation-button" @click.prevent="$emit('cancel')">No</button>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: "ConfirmationPopup",
  props: {
    message: String
  },
  emits: [ 'confirm', 'cancel' ]
}
</script>

<style scoped>

.confirmation-popup {
  display: grid;
  justify-content: center;
  background: lightgray;
  min-width: 450px;
  max-width: 450px;
  min-height: 180px;
  max-height: 180px;
  border-radius: 5px;
  overflow-y: inherit;
  overflow-x: inherit;
  grid-template-rows: 1fr 1fr;
}

.message-div {
  margin-top: 10px;
}

.confirmation-buttons {
  display: flex;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 15px;
  gap: 5px;
}

.confirmation-button {
  height: auto;
  width: 75px;
}
</style>