<template>
  <div class="app-div">
    <ReportsPopup v-if="this.reportsPopupEnabled" @close="this.reportsPopupEnabled=false"/>
    <div>
      <!-- App Navigation Menu -->
      <Slide noOverlay v-if="!navigationBlacklist.includes(currentRouteName)">
        <section class="navigation-section">
          <!-- <button class="green-button navigation-button" @click.prevent="navigateTo('/dashboard')">Dashboard</button> -->
          <button class="green-button navigation-button" @click.prevent="navigateTo('/inventory')">Inventory</button>
          <button class="green-button navigation-button" @click.prevent="navigateTo('/receipts')">Receipts</button>
         <!-- <button class="green-button navigation-button" @click.prevent="navigateTo('/templates')">Templates</button> -->
        </section>
        <section class="bottom-navigation-section">
          <button class="green-button navigation-button" @click.prevent="this.reportsPopupEnabled=true">Reports</button>
          <button class="green-button navigation-button" @click.prevent="openXeroIntegration()">Xero Integration</button>
          <button class="green-button navigation-button" @click.prevent="logout()">Logout</button>
        </section>
      </Slide>
    </div>

    <div>
      <!-- Router View -->
      <router-view v-slot="{ Component }">
        <transition name="fade" mode="out-in" :duration="200">
          <component :is="Component"/>
        </transition>
      </router-view>
    </div>
  </div>
</template>

<script>

import { Slide } from 'vue3-burger-menu';
import jwtUtil from '@/utilities/jwtUtil';
import router from '@/router';
import ApiCaller from '@/utilities/apiCaller';
import ReportsPopup from '@/components/popups/ReportsPopup';

export default {
  name: 'App',
  components: {
    Slide,
    ReportsPopup
  },
  data() {
    return {
      navigationBlacklist: [
          'Login'
      ],
      xeroUrl: '',
      reportsPopupEnabled: false
    }
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    }
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      let isAdmin = await ApiCaller.fetchData('/security/isAdmin');
      if(isAdmin === 'true') { this.xeroUrl = await ApiCaller.fetchData('/xero/authentication-link'); }
    },
    navigateTo(link) {
      this.$router.push(link);
    },
    openXeroIntegration() {
      if(this.xeroUrl === '') { return; }
      window.open(this.xeroUrl,
          'popup-window',
          'height=600, width=800');
    },
    logout() {
      jwtUtil.clearJWTs();
      router.push({ path: '/'});
    }
  }
}
</script>

<style>

.app-div {
  padding: inherit;
  height: inherit;
  width: inherit;
  background: inherit;
}

.navigation-section {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 10px;
  left: 10px;
  top: 20px;
  gap: 10px;
}

.navigation-button {
  height: 50px;
}

.bottom-navigation-section {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 10px;
  left: 10px;
  bottom: 0;
  gap: 10px;
}

</style>