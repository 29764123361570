class ItemChecker {

    static isValidItem(item) {
        if(item.itemName === null || item.itemName.length === 0) {
            alert('Item Name is Empty!')
            return false;
        }

        if(item.itemType === null || item.itemType.length === 0) {
            alert('Item Type is Empty!')
            return false;
        }

        if(item.subItemType === null || item.subItemType.length === 0) {
            alert('Sub Item Type is Empty!')
            return false;
        }

        if(item.itemStatus === null || item.itemStatus.length === 0) { item.itemStatus = 'INSTOCK'; }

        return true;
    }

    static isValidItemsOnReceipt(items) {
        for(let index = 0; index < items.length; index++) {
            let item = items[index];
            if(item.itemName === null || item.itemName.length === 0) { alert('An item does not have an item name!'); return false; }
            if(item.itemType === null || item.itemType.length === 0) { alert(item.itemName + ' does not have a item type!'); return false; }
            if(item.subItemType === null || item.subItemType.length === 0) { alert(item.itemName + ' does not have a sub-item type!'); return false; }
            if(item.unitCost === 0) { alert(item.itemName + ' does not have a unit cost!'); return false; }
            if(item.quantity === 0) { alert(item.itemName + ' has no quantity!'); return false; }
        }
        return true;
    }
}

export default ItemChecker;