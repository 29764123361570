<template>
  <main class="main-page">
    <ConfirmationPopup v-if="this.confirmationPopupEnabled" @confirm="this.remove()" @cancel="this.confirmationPopupEnabled=false" :message="'Are you sure you want to remove this receipt? This will also remove all items associated with the receipt!'"/>
    <label class="main-title">Receipt Details</label>
    <a target="_blank" rel="noopener noreferrer" :href="'https://go.xero.com/Bank/ViewTransaction.aspx?bankTransactionID=' + this.receipt.bankTransactionId" v-if="this.receipt.bankTransactionId != null">Xero Account Transaction</a>
    <ReceiptDetails ref="receiptDetails"
                    :receipt-data="this.receipt"
                    :items-data="this.items"/>
    <div class="button-section">
      <button class="green-button details-buttons" @click.prevent="this.handleReturn()">Return an Item</button>
      <button class="green-button details-buttons" @click.prevent="this.update()">Update</button>
      <button class="green-button details-buttons" @click.prevent="this.confirmationPopupEnabled=true">Remove</button>
    </div>
  </main>
</template>

<script>
import {useRoute} from 'vue-router';
import ApiCaller from '@/utilities/apiCaller';
import ReceiptDetails from '@/components/details/ReceiptDetails';
import ConfirmationPopup from '@/components/popups/ConfirmationPopup';
import router from '@/router';
import ReceiptDetailsUtil from '@/utilities/receiptDetailsUtil';
import Calculations from '@/utilities/calculations';
import ReceiptMapper from '@/utilities/receiptRemapper';
import ItemChecker from "@/utilities/itemChecker";
import ReceiptChecker from "@/utilities/receiptChecker";

export default {
  name: "ReceiptDetailsView",
  components: { ConfirmationPopup, ReceiptDetails },
  data() {
    return {
      uniqueId: useRoute().params.uniqueId.toString(),
      receipt: {},
      items: [],
      confirmationPopupEnabled: false
    }
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      const receiptUri = '/api/v1/receipts/?uniqueId=' + this.uniqueId;
      let receiptJsonData = await ApiCaller.fetchData(receiptUri);

      if(receiptJsonData === '[]') {
        await router.push({path: '/receipts'});
        return;
      }

      this.receipt = JSON.parse(receiptJsonData)[0];

      const itemsUri = '/api/v1/items/?receiptId=' + this.uniqueId;
      let itemsJsonData = await ApiCaller.fetchData(itemsUri);

      let rawItems = JSON.parse(itemsJsonData);

      this.items = ReceiptDetailsUtil.combineItems(rawItems);
    },
    async handleReturn() {
      await router.push({path: '/returns/create/' + this.receipt.uniqueId });
    },
    async update() {
      // Verify the data
      if(!ReceiptChecker.isValidReceipt(this.receipt)) { return; }

      if(typeof this.receipt.shippingAmount === 'string') { this.receipt.shippingAmount = 0.0; }
      if(typeof this.receipt.taxAmount === 'string') { this.receipt.taxAmount = 0.0; }
      if(typeof this.receipt.coupon === 'string') { this.receipt.coupon = 0.0; }

      if(this.items.length === 0) {
        alert('There are no items!')
        return;
      }

      if(!ItemChecker.isValidItemsOnReceipt(this.items)) { return; }

      // Calculate the Price.
      let totalCost = this.$refs.receiptDetails.totalCost - this.receipt.shippingAmount;
      this.items.forEach(item => {
        let unitPrice = Calculations.calculateUnitPrice(item.unitCost, item.markup) + Calculations.calculateShipping(item.unitCost, item.quantity, totalCost, this.receipt.shippingAmount);
        item['unitPrice'] = Number(unitPrice.toFixed(2));
      });

      let data = ReceiptMapper.remap(this.receipt, this.items);
      data['uniqueId'] = this.uniqueId;

      const uri = '/api/v1/receipts/update';
      const stringifyData = JSON.stringify(data);

      let response = await ApiCaller.sendData(uri, stringifyData, 'PUT');

      if(!response.includes('Success')) { alert('Failed to update the receipt because it has either been reconciled in Xero or there are other issues...'); }

      await this.uploadAttachment(); // Upload the attachment

      if(response.includes('Success')) {
        await router.push({path: '/receipts'});
      }
    },
    async uploadAttachment() {
      let file = this.$refs.receiptDetails.file;

      if(file.length === 0) { return; }

      const uri = `/xero/attachment/upload/bankTransactionId=${ this.receipt.bankTransactionId }`;

      let formData = new FormData();
      formData.append('file', file, file.name);

      await ApiCaller.sendUnknownTypeData(uri, formData, 'POST');
    },
    async remove() {
      this.confirmationPopupEnabled = false;
      const uri = '/api/v1/receipts/delete/uniqueId=' + this.uniqueId;
      let response = await ApiCaller.deleteData(uri);

      if(response.includes('Success')) {
        await router.push({path: '/receipts'});
      } else {
        alert('Cannot delete receipt: ' + this.uniqueId + " ERROR: "  + response);
      }
    }
  }
}
</script>

<style scoped>

.main-page {
  display: flex;
  flex-direction: column;
  align-items: safe center;
}

.button-section {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  gap: 10px;
}

.details-buttons {
  margin-top: 10px;
  margin-bottom: 10px;
  height: 50px;
}

</style>