<template>
  <div class="data-table">
    <table class="main-table">
      <thead class="table-header">
        <tr>
          <th id="table-headers" class="main-th" v-for="th in this.columns" :key="th" @click.prevent="this.headerSelected(th)">{{ th }}</th>
        </tr>
      </thead>
      <tbody>
        <component :is="this.table" :items="this.tableData" @item-selected="this.itemSelected"/>
      </tbody>
    </table>
  </div>
</template>

<script>
import InventoryTable from '@/components/tables/InventoryTable';
import ReceiptsTable from '@/components/tables/ReceiptsTable';
import TemplatesTable from '@/components/tables/TemplatesTable';

export default {
  name: 'BaseTable',
  components: { InventoryTable, ReceiptsTable, TemplatesTable },
  props: {
    table: String,
    columns: Array,
    items: Array
  },
  emits: ['header-selected', 'item-selected'],
  computed: {
    tableData() {
      return this.items || []
    }
  },
  methods: {
    headerSelected(header) {
      this.$emit('header-selected', header);
    },
    itemSelected(uniqueId) {
      this.$emit('item-selected', uniqueId);
    }
  }
}
</script>

<style scoped>

.data-table {
  display: flex;
  box-shadow: 0 0 10px rgba(114, 114, 113, 0.5);
  min-width: 100%;
  border-radius: 5px;
}

.table-header {
  height: 80px;
}

.green-table {
  background-color: #74ae35;
}

.red-table {
  background-color: #f7363d;
}

.blue-table {
  background-color: #4287f5;
}

</style>