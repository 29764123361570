
class ReceiptDetailsUtil {

    static combineItems(items) {
        let combinedItems = [];

        item_loop: for(let i = 0; i < items.length; i++) {
            let item = items[i];

            for(let i = 0; i < combinedItems.length; i++) {
                let combinedItem = combinedItems[i];
                if(combinedItem.itemName?.toLowerCase() === item.itemName.toLowerCase()) {
                    combinedItem.quantity += 1;
                    continue item_loop;
                }
            }

            let isSupplies = false;

            if(item.itemType === 'SUPPLIES') { isSupplies = true; }

            let combinedItem = {
                itemName: item.itemName,
                itemType: item.itemType,
                subItemType: item.subItemType,
                unitCost: item.unitCost,
                markup: item.markup,
                supplies: isSupplies,
                quantity: 1
            }

            combinedItems.push(combinedItem);
        }

        return combinedItems;
    }
}

export default ReceiptDetailsUtil;
